import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormFieldGroup,
  useTextField
} from "@/components/FormBuilder/Helpers";

export const stepOne: FormBlock[] = [
  ...useFormFieldGroup(
    [
      useTextField({
        key: "firstName",
        label: __getText("fields", "firstNameLabel"),
        placeholder: __getText("fields", "firstNameLabel"),
        required: true
      }),
      useTextField({
        key: "lastName",
        label: __getText("fields", "lastNameLabel"),
        placeholder: __getText("fields", "lastNameLabel"),
        required: true
      }),
      useTextField({
        key: "displayName",
        label: __getText("fields", "displayNameLabel"),
        placeholder: __getText("fields", "displayNameLabel"),
        required: true
      })
    ],
    { layout: "3-col" }
  )
];

export const stepTwo: FormBlock[] = [
  ...useFormFieldGroup(
    [
      useTextField({
        key: "email",
        label: __getText("fields", "emailFieldLabel"),
        placeholder: __getText("fields", "emailFieldLabel"),
        required: true,
        validations: [
          {
            method: "email",
            error: "invalid email"
          }
        ]
      }),
      useTextField({
        key: "phone.primary",
        label: __getText("fields", "primaryPhoneNumberLabel"),
        placeholder: __getText("fields", "primaryPhoneNumberLabel"),
        required: true
      }),
      useTextField({
        key: "phone.secondary",
        label: __getText("fields", "secondaryPhoneNumberLabel"),
        placeholder: __getText("fields", "secondaryPhoneNumberLabel"),
        required: false
      })
    ],
    { layout: "3-col" }
  )
];

export const stepThree: FormBlock[] = [
  ...useFormFieldGroup(
    [
      useTextField({
        key: "email2",
        label: __getText("fields", "emailFieldLabel"),
        placeholder: __getText("fields", "emailFieldLabel"),
        required: true,
        validations: [{ method: "email", error: "Invalid email" }]
      }),
      useTextField({
        key: "email3",
        label: __getText("fields", "emailFieldLabel"),
        placeholder: __getText("fields", "emailFieldLabel"),
        required: true,
        validations: [{ method: "email", error: "Invalid email" }]
      })
    ],
    { layout: "3-col" }
  )
];
