
import Vue from "vue";
import HeaderStep from "./HeaderStep.vue";
export default Vue.extend({
  name: "MultiStepFormHeaderRow",
  components: { HeaderStep },
  props: {
    currentStepNumber: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
});
