
import MultiStepForm from "@/components/MultiStepForm/MultiStepForm.vue";
import { stepOne, stepTwo, stepThree } from "@/forms/admin/test.multistep";

import Vue from "vue";
export default Vue.extend({
  name: "MultiStepFormExample",
  components: { MultiStepForm },
  data(): any {
    return {
      currentStepNumber: 0,
      errorMessage: "",
      successMessage: "",
      // this would be the saved values in store. the whole form, all steps, should be one object.
      // this could be computed property if needed to create the object from various inputs
      savedValues: {
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        email2: "",
        email3: "",
        "phone.primary": "",
        "phone.secondary": ""
      },
      // this would be the edit values in store.
      // this could be computed property if needed to create the object from various inputs
      editValues: {
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        email2: "",
        email3: "",
        "phone.primary": "",
        "phone.secondary": ""
      },
      availableSteps: [
        {
          id: "StepOne",
          title: "Application",
          description: "Vitae sed mi luctus laoreet.",
          component: "FormBuilder",
          schema: stepOne,
          required: []
        },
        {
          id: "StepTwo",
          title: "Underwriting",
          description: "Cursus semper viverra.",
          component: "FormBuilder",
          schema: stepTwo,
          required: ["StepOne"]
        },
        {
          id: "StepThree",
          title: "History/Lienholder",
          description: "Penatibus eu quis ante.",
          component: "FormBuilder",
          schema: stepThree,
          required: ["StepOne", "StepTwo"]
        }
      ]
    };
  },
  methods: {
    async saveMethod() {
      // tThis is where we'd save to server. probably via store action
      // below is just for demo purposes...
      for (const key of Object.keys(this.editValues)) {
        this.savedValues[key] = this.editValues[key];
      }
    },
    onStepEdit({ key, value }: { key: string; value: any }) {
      // handle edits to wherever
      this.editValues[key] = value;
    },
    stepsCompletedHandler() {
      this.$appNotifySuccess("Form completed succesffully");
      //Do anything you want, show a success screen or summat
      //
    }
  }
});
