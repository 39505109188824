var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"relative overflow-hidden lg:flex-1 select-none",class:_vm.step.disabled
      ? 'cursor-not-allowed'
      : _vm.stepIndex === _vm.currentStepNumber
      ? 'cursor-auto'
      : 'cursor-pointer',on:{"click":_vm.handleClickStep}},[_c('div',{class:[
      _vm.stepIndex === 0 ? 'border-b-0 rounded-t-md' : '',
      _vm.stepIndex === _vm.currentStepNumber ? 'border-t-0 rounded-b-md' : '',
      'border border-green-200 overflow-hidden lg:border-0'
    ]},[(_vm.stepIndex === _vm.currentStepNumber)?_c('div',{attrs:{"aria-current":"step"}},[_c('span',{staticClass:"absolute top-0 left-0 w-1 h-full bg-atlas-blue lg:w-full lg:h-1 lg:bottom-0 lg:top-auto",attrs:{"aria-hidden":"true"}}),_c('span',{class:[
          _vm.stepIndex !== 0 ? 'lg:pl-9' : '',
          'px-6 py-5 flex items-start text-sm font-medium'
        ]},[_c('span',{staticClass:"mt-0.5 ml-4 min-w-0 flex flex-col"},[_c('span',{staticClass:"text-xs font-semibold text-atlas-blue tracking-wide uppercase"},[_vm._v(_vm._s(_vm.step.title))]),_c('span',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(_vm._s(_vm.step.description))])])])]):_c('div',{staticClass:"group"},[_c('span',{staticClass:"absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto",attrs:{"aria-hidden":"true"}}),_c('span',{class:[
          _vm.stepIndex !== 0 ? 'lg:pl-9' : '',
          'px-6 py-5 flex items-start text-sm font-medium'
        ]},[_c('span',{staticClass:"mt-0.5 ml-4 min-w-0 flex flex-col"},[_c('span',{staticClass:"text-xs font-semibold text-gray-500 tracking-wide uppercase"},[_vm._v(_vm._s(_vm.step.title))]),_c('span',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(_vm._s(_vm.step.description))])])])]),(_vm.stepIndex !== 0)?[_c('div',{staticClass:"hidden absolute top-0 left-0 w-3 inset-0 lg:block",attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-full w-full text-gray-300",attrs:{"viewBox":"0 0 12 82","fill":"none","preserveAspectRatio":"none"}},[_c('path',{attrs:{"d":"M0.5 0V31L10.5 41L0.5 51V82","stroke":"currentcolor","vector-effect":"non-scaling-stroke"}})])])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }