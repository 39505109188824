
import Vue from "vue";
export default Vue.extend({
  name: "MultiStepFormHeaderStep",
  props: {
    currentStepNumber: {
      type: Number,
      default: 0
    },
    stepIndex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: true
    },
    step: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClickStep() {
      const { step, stepIndex, currentStepNumber } = this;
      const { disabled, id } = step;
      if (!disabled && stepIndex !== currentStepNumber) {
        this.$emit("handleClickStep", id);
      }
    }
  }
});
