
import Vue from "vue";
import HeaderRow from "./Header/HeaderRow.vue";
import { validateSchema } from "@/helpers/validateSchema";
import { objectDifference } from "@/helpers";
import {
  FormBuilderButtons,
  IFormBuilderStep
} from "@/components/FormBuilder/types";

export interface IData {
  availableSteps: IFormBuilderStep[];
  savedValues: Record<string, any>;
  editValues: Record<string, any>;
  successMessage: string;
  errorMessage: string;
  currentStepNumber: number;
}

export default Vue.extend({
  name: "MultiStepForm",
  props: {
    successMessage: {
      type: String,
      required: false,
      default: ""
    },
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    savedValues: {
      type: Object,
      required: false,
      default: () => ({})
    },
    editValues: {
      type: Object,
      required: false,
      default: () => ({})
    },
    availableSteps: {
      type: Array as Vue.PropType<IFormBuilderStep[]>,
      required: true,
      default: () => []
    },
    saveMethod: {
      type: Function,
      required: false,
      default: () => () => {}
    }
  },
  components: { HeaderRow },
  data() {
    return {
      currentStepNumber: 0
    };
  },
  methods: {
    // probably emit this
    // probably emit this
    // This is for mutating form values. probably via store mutation
    // probably emit this
    onButtonClicked(event: string) {
      switch (event) {
        case "save":
          this.saveMethod();
          break;
        case "saveNext":
          this.saveMethod().then(() => this.goToNextStep());
          break;
        case "next":
          this.goToNextStep();
          break;
        case "complete":
          this.$emit("completed");
      }
    },
    goToNextStep(): void {
      const currentStep = this.currentStepNumber;
      const totalSteps = this.steps.length;
      if (currentStep + 1 < totalSteps) {
        this.currentStepNumber = this.currentStepNumber + 1;
      }
    },
    goToPrevStep(): void {
      const currentStepNumber = this.currentStepNumber;
      if (currentStepNumber - 1 >= 0) {
        this.currentStepNumber = this.currentStepNumber - 1;
      }
    },
    goToStepById(stepId: string): void {
      //Probably check if canGoToStep
      //As in all requirements met
      const number = this.steps.findIndex(
        (s: IFormBuilderStep) => s.id === stepId
      );
      this.currentStepNumber = number;
    },
    isStepComplete(stepId: string): boolean {
      const step = this.availableSteps.find(
        (s: IFormBuilderStep) => s.id === stepId
      );
      const { schema } = step as any;
      const { isValid } = validateSchema(schema, this.savedValues);
      return isValid;
    },
    isStepValid(stepId: string): boolean {
      const step = this.availableSteps.find(
        (s: IFormBuilderStep) => s.id === stepId
      );
      const { schema } = step as any;
      const { isValid } = validateSchema(schema, this.editValues);
      return isValid;
    },
    // probably emit this
    formBuilderButtonClickedHandler(button: FormBuilderButtons): void {
      switch (button.title) {
        case "Save and Continue":
          this.onButtonClicked("saveNext");
          break;
        case "Save":
          this.onButtonClicked("save");
          break;
        case "Next":
          this.onButtonClicked("next");
          break;
        case "Previous":
          this.goToPrevStep();
          break;
      }
    },
    availableStepsComplete(): boolean {
      return this.availableSteps
        .map((step: IFormBuilderStep) => this.isStepComplete(step.id))
        .every((complete: boolean) => complete);
    }
  },
  computed: {
    steps(): IFormBuilderStep[] {
      // eslint-disable-next-line prettier/prettier
      return this.availableSteps.map((s) => {
        const complete = this.isStepComplete(s.id);
        // eslint-disable-next-line prettier/prettier
        const aa = s.required.map((n) => !this.isStepComplete(n));
        // eslint-disable-next-line prettier/prettier
        const disabled = aa.filter((s) => s).length > 0;
        return {
          ...s,
          disabled,
          complete
        };
      });
    },
    currentStep(): IFormBuilderStep {
      const { currentStepNumber, steps } = this;
      const step = steps[currentStepNumber];
      return step;
    },
    hasUnsavedChanges(): boolean {
      const { savedValues, editValues } = this;
      const difference = objectDifference(editValues, savedValues);
      return Object.keys(difference).length > 0;
    },
    primaryButton(): { text: string; key: string } | null {
      const { hasUnsavedChanges, isStepValid, currentStep } = this;
      if (hasUnsavedChanges && isStepValid(currentStep.id)) {
        return {
          text: "Save and Continue",
          key: "saveNext"
        };
      }
      if (hasUnsavedChanges) {
        return {
          text: "Save",
          key: "save"
        };
      }
      if (
        this.isStepComplete(currentStep.id) &&
        !this.availableStepsComplete()
      ) {
        return {
          text: "Next",
          key: "next"
        };
      }
      if (this.availableStepsComplete()) {
        return {
          text: "Complete",
          key: "complete"
        };
      }
      return null;
    },
    footerButtons(): any[] {
      const buttons = [];

      const {
        hasUnsavedChanges,
        isStepValid,
        currentStep,
        currentStepNumber
      } = this;

      if (this.currentStepNumber === this.availableSteps.length - 1) {
        if (currentStepNumber > 0) {
          buttons.push({
            title: "Previous",
            show: true,
            class: "float-left",
            type: "primary"
          });
        }
        return buttons;
      }

      if (hasUnsavedChanges && isStepValid(currentStep.id)) {
        buttons.push({
          title: "Save and Continue",
          show: true,
          class: "float-right",
          type: "primary"
        });
      }

      if (hasUnsavedChanges) {
        buttons.push({
          title: "Save",
          show: true,
          class: "float-right mr-4",
          type: "primary"
        });
      }

      if (this.isStepComplete(currentStep.id)) {
        buttons.push({
          title: "Next",
          show: true,
          class: "float-right",
          type: "primary"
        });
      }

      if (currentStepNumber > 0) {
        buttons.push({
          title: "Previous",
          show: true,
          class: "float-left",
          type: "primary"
        });
      }

      return buttons;
    }
  }
});
